.h1-transform{
    color: var(--color-text);
}

.header-merkez{
    background-color: var(--color-bg2);
}

.nav-button{
 background-color:var(--color-bg);
 border: 1px solid var(--color-bg2);
 border-radius: 10px;
 -webkit-border-radius: 10px;
 -moz-border-radius: 10px;
 -ms-border-radius: 10px;
 -o-border-radius: 10px;
}

.nav-button:hover{
    background-color:var(--color-bg2);
    border: 1px solid var(--color-bg);
}

.esas-image{
    border-radius: 12px;
}


.button2 {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #c5c5c5;
    padding: 0.4em 0.8em;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0.5em;
    background: var(--color-bg2);
    border: 1px solid var(--color-bg);
   
  }
  
  .button2:active {
    color: #fff;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }
  
  .button2:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .button2:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color:var(--color-bg);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .button2:hover {
    color: var(--color-text);
    border: 1px solid var(--color-bg2);
  }
  
  .button2:hover:before {
    top: -35%;
    background-color: var(--color-bg2);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .button2:hover:after {
    top: -45%;
    background-color: var(--color-bg);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
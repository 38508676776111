.products-h1{
    text-shadow: 0 0 3px #444655;
}

.product-container-sidebar{
    background-color: var(--color-bg);
}

.sidebar-active{
    background-color: var(--color-bg2);
}
.footer{
    background-color: var(--color-bg);
}

.pale-green{
   background-color: var( --color-bg2);
}

@media (max-width: 640px) { 
    .footer-links li{
        font-size: x-small;
    }
    .footer p{
        font-size: small;
    }


}

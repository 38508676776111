.esas{
    color: black;
}


.esas-read-more {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #c5c5c5;
    padding: 0.3em 0.5em;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0.5em;
    background: rgb(209 213 219);
    border: 1px solid var(--color-bg2);
   
  }
  
  .esas-read-more:active {
    color: #fff;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }
  
  .esas-read-more:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(226, 179, 179, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .esas-read-more:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color:var(--color-bg);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .esas-read-more:hover {
    color:rgb(209 213 219) ;
    border: 1px solid var(--color-bg);
  }
  
  .esas-read-more:hover:before {
    top: -35%;
    background-color: var(--color-bg);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .esas-read-more:hover:after {
    top: -45%;
    background-color: var(--color-text);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
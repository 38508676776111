.contact-button{
    background-color:var(--color-bg);
    border: 1px solid var(--color-bg2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.contact-button:hover{
    background-color:var(--color-bg2);
    border: 1px solid var(--color-bg);
}
.who-we-are-about{
    background-color: var(--color-bg2);
}

.about-us-team{
    
        border-radius: 12px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.545);
}

.about-us-oil{
    border-radius: 12px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.545);
}
.partners-back{
    color: var(--color-bg);
}

.zoom-pic{
   
        transition: transform .2s; /* Animation */
      
}

.zoom-pic:hover{
        transform: scale(1.2);
        /*(150%zoom-Note: if the zoom is too large, it will go outside of the viewport) */      ;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
}

.our-partners{
        color: var(--color-bg);
}